/**
 * Embed code to be loaded on arbitrary sites, for loading the customer-search widget.
 * As we don't know where this will be loaded, there are a few restrictions:
 * - We must not use jQuery
 * - We must not rely on or pollute the window object
 * - We should be resilient towards the script being loaded using various methods (async, in head vs body, etc)
 *
 * This script finds a HTML div and fills it with an embedded customer-search map. The HTML element must look like this:
 * `<div id="flocert-customer-search-widget" data-site-id="1234" data-domain="https://flocert.net/"></div>`
 *
 * data-site-id is an identifier for the embed, which controls config such as language and styling.
 * 	This site-ID will be provided to third-party sites by Flocert
 * data-domain is optional to allow for testing against localhost or staging and will fallback to https://flocert.net/
 */
;(function(){
	"use strict"
	function FlocertCustomerSearchWidget()
	{
		var settings = {
			defaultDomain: 'https://www.flocert.net/'
		}

		var global = {}

		var fn = {
			ready: function(fn)
			{
				if (document.readyState != 'loading'){
					fn();
				} else {
					document.addEventListener('DOMContentLoaded', fn);
				}
			},

			consoleError: function(messsage)
			{
				if (typeof console.error == "function") {
					console.error(messsage);
				} else {
					console.log(messsage);
				}
			},

			init: function()
			{
				global.widget = document.getElementById('flocert-customer-search-widget');
				if (!global.widget)
				{
					fn.consoleError("FlocertCustomerSearch -> #flocert-customer-search-widget element not found -> Widget could not be rendered")
					return;
				}

				global.siteId = global.widget.getAttribute('data-site-id');
				if (global.siteId === null || !global.siteId.length)
				{
					fn.consoleError("FlocertCustomerSearch -> Site ID (data-side-id) not found -> Widget could not be rendered")
					return;
				}

				global.domain = global.widget.getAttribute('data-domain');
				if (global.domain === null || !global.domain.length)
				{
					global.domain = settings.defaultDomain;
				}

				global.iframe = fn.createIframe();
				global.widget.innerHTML = '';
				global.widget.appendChild(global.iframe);

				fn.listenForPostMessage();
			},

			createIframe: function()
			{
				var iframe = document.createElement('iframe');
				iframe.src = global.domain + 'widget_customersearch/' + global.siteId;
				iframe.style.width = '100%';
				iframe.style.verticalAlign = 'bottom';
				iframe.scrolling = 'no';
				iframe.setAttribute('frameBorder', '0');
				iframe.style.minHeight = '650px';
				return iframe;
			},

			listenForPostMessage: function()
			{
				if ( window.addEventListener ) {
					window.addEventListener('message', fn.handlePostMessage, false);
				} else if ( window.attachEvent ) { // ie8
					window.attachEvent('onmessage', fn.handlePostMessage);
				}
			},

			handlePostMessage: function(e)
			{
				if (!fn.verifyOrigin(e))
				{
					return;
				}

				// parse data
				var data = JSON.parse(e.data);

				if (typeof data['docHeight'] === 'number')
				{
					// 4px buffer added for IE
					global.iframe.style.height = data['docHeight'] + 4 + 'px';
					global.iframe.style.overflow = 'hidden';
				}
				else if (typeof data['scrollOffset'] === 'number')
				{
					var widgetOffset = global.widget.offsetTop;
					var scrollTop = data['scrollOffset'] + widgetOffset;
					if (typeof data['scrollHeaderElement'] === 'string' && data['scrollHeaderElement'] !== '')
					{
						var headerElem = document.querySelector(data['scrollHeaderElement']);
						if (headerElem)
						{
							scrollTop = scrollTop - parseFloat(getComputedStyle(headerElem, null).height.replace("px", ""))
						}
					}

					window.scroll({
						top: scrollTop,
						behavior: 'smooth'
					});
				}
				
				var mapParentOverlay = document.querySelector('.customer-search-map-overlay');

				if (!mapParentOverlay)
				{
					var mapParentOverlay = document.createElement('div');
					mapParentOverlay.classList.add('customer-search-map-overlay');
					document.body.appendChild(mapParentOverlay);
				}
			
				if (data['showOverlay'] === 'true')
				{
					mapParentOverlay.classList.add('customer-search-map-overlay--visible');
				}
				else if (data['showOverlay'] === 'false')
				{
					mapParentOverlay.classList.remove('customer-search-map-overlay--visible');
				}
			},

			verifyOrigin: function(e)
			{
				var expectedOrigin = global.domain;
				if (expectedOrigin[global.domain.length - 1] === '/') {
					expectedOrigin = expectedOrigin.slice(0, expectedOrigin.length - 1)
				}

				return (e.origin === expectedOrigin);
			}
		}

		fn.ready(fn.init);
	}

	new FlocertCustomerSearchWidget();
})();